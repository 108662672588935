import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { projectDetails } from "./projects/ProjectDetails";
import ProjectDetail from "../../components/ProjectDetail/ProjectDetail";
import "./Work.scss";

function Work() {
  const projectWrapper = useRef();
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [isMobile] = useState(window.innerWidth < 769);
  const [open, setOpen] = useState("");
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };
  console.log(open);
  const showImage = (e, width, height) => {
    setX(isMobile ? "5vw" : e.pageX - width);
    setY(e.pageY - height);
  };

  const openProjectDetail = (project) => {
    setOpen(project);
  };
  let vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  return (
    <div id="work">
      <div className="title">our work</div>
      <div className="flex column render-project">
        {projectDetails.map((project) => {
          return (
            <div
              className="project-detail show-visible"
              key={project.title}
              ref={projectWrapper}
            >
              <div
                className="project-title"
                key={project.short}
                onClick={() => openProjectDetail(project)}
                onMouseEnter={(e) =>
                  showImage(
                    e,
                    getRandomInt(window.innerWidth / 1.5),
                    getRandomInt(window.innerHeight / 2.5)
                  )
                }
              >
                {project.title}
              </div>

              {!isMobile && (
                <img
                  style={{
                    top: y,
                    left: x,
                    width: `${isMobile ? "90vw" : "60vw"}`,
                  }}
                  src={project.image}
                />
              )}
            </div>
          );
        })}
      </div>
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            initial={{ x: 0, y: vh }}
            animate={{ x: 0, y: 0 }}
            exit={{ x: 0, y: vh }}
            className="motion-background"
            key="home"
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            <ProjectDetail project={open} open={open} setOpen={setOpen} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Work;
