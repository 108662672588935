import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as emailjs from "@emailjs/browser";
import Button from "../../components/Button/Button";
import Typed from "react-typed";
import "./Contact.scss";

function Contact() {
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState("");

  const onSubmit = (data) => {
    let templateParams = {
      from_email: data.email,
      from_name: data.name,
      to_name: "Meg and Irène",
      subject: "new email!",
      message: data.comment,
    };

    emailjs
      .send(
        "service_noftwbd",
        "template_contact",
        templateParams,
        "user_izVfJMcVn7IOThfB1YKHv"
      )
      .then(() => {
        setIsLoading("sent");
      });
  };

  return (
    <div id="contact">
      <div className="title">contact us</div>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="subtitle">
          <div className="flex">
            <span>You need a new </span>
            <Typed
              strings={["website?", "web app?", "web design?"]}
              typeSpeed={40}
              backSpeed={50}
              attr="placeholder"
              loop
              className="width-50"
            >
              <input type="text" />
            </Typed>
          </div>
          <br />
          Great! We’re in!
          <strong>Let’s get in touch.</strong>
        </div>
        <div className="input-fields">
          <div className="name input-container">
            <input {...register("name")} placeholder="name" />
            <span className="input-bar" />
          </div>

          <div className="email input-container">
            <input {...register("email")} placeholder="email" />
            <span className="input-bar" />
          </div>
        </div>
        <div className="comment input-container">
          <textarea {...register("comment")} placeholder="comment" />
        </div>
        <Button
          text={isLoading === "sent" ? "Sent! Thanks!" : "Let's go!"}
          disabled={isLoading}
          isLoading={isLoading === "sending"}
          className={isLoading}
        />
      </form>
    </div>
  );
}

export default Contact;
