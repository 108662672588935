import React from "react";
import { useApp } from "../../context/AppContext";
import "./Hamburger.scss";

const Hamburger = ({ toggleMenu, menuOpen }) => {
  const { setCurrentStep } = useApp();

  return (
    <label className="hamburger-button">
      <input type="checkbox"></input>
      <span className="menu">
        <span className="hamburger"></span>
      </span>
      <ul>
        <li>
          <div className="menu-link" onClick={() => setCurrentStep("home")}>
            home
          </div>
        </li>
        <li>
          <div className="menu-link" onClick={() => setCurrentStep("work")}>
            our work
          </div>
        </li>
        <li>
          <div className="menu-link" onClick={() => setCurrentStep("about")}>
            about us
          </div>
        </li>
        <li>
          <div className="menu-link" onClick={() => setCurrentStep("contact")}>
            contact us
          </div>
        </li>
      </ul>
    </label>
  );
};

Hamburger.displayName = "Hamburger";

export default Hamburger;
