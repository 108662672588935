import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ImageSlider from "../ImageSlider/ImageSlider";

import "./ProjectDetail.scss";

function ProjectDetail({ project, setOpen }) {
  return (
    <div className="project-detail-large">
      <div className="description">
        <div className="project-title">{project.title}</div>
        <div className="close-wrapper" onClick={() => setOpen("")}>
          <div className="close"></div>
        </div>
        <div className="brief">
          <strong>Client brief:</strong> {project.brief}
        </div>
        <div className="technologies">
          <strong>Technologies used:</strong> {project.technologies}
        </div>
        <div className="actions">
          <div>
            <strong>What we did:</strong>
          </div>
          <div>
            {project.actions.map((action) => {
              return <div key={action}>{action}</div>;
            })}
          </div>
        </div>
        <div className="link">
          <a href={project.path} target="_blank" rel="noopener">
            <strong>Click here to see it in action</strong>{" "}
          </a>
        </div>
      </div>
      <div className="images">
        <ImageSlider images={project.images} alt={project.title} />
      </div>
    </div>
  );
}

export default ProjectDetail;
