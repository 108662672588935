import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as regularCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as solidCircle } from "@fortawesome/free-solid-svg-icons";
import "./Dot.scss";

const Dot = ({ solid, onClick }) => {
  return (
    <FontAwesomeIcon
      icon={solid ? solidCircle : regularCircle}
      className="dadot"
      onClick={onClick}
    />
  );
};

Dot.displayName = "Dot";

export default Dot;
