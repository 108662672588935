import React, { useState } from "react";
import { useApp } from "../../context/AppContext";
import Hamburger from "../Hamburger/Hamburger";
import Dot from "../Dot/Dot";
import { images } from "../../assets/images/logoImages";
import "./Header.scss";

const Header = () => {
  const brandName = "MIYU";
  const { menuList, currentStep, setCurrentStep } = useApp();
  const [menuOpen, setMenuOpen] = useState(false);

  const letters = menuList.map((val, index) => {
    return (
      <div
        className="header-logo"
        key={index}
        onClick={() => setCurrentStep(val)}
      >
        <Dot solid={currentStep === val} />
        <div>
          <img
            src={images[`letter${brandName[index]}`]}
            alt={`letter-${brandName[index]}`}
          />
        </div>
      </div>
    );
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div id="header">
      <div className="flex">
        <div className="flex letters">{letters}</div>
        <Hamburger toggleMenu={toggleMenu} menuOpen={menuOpen} />
      </div>
    </div>
  );
};

Header.displayName = "Header";

export default Header;
