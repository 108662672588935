import React from "react";
import Slider from "react-slick";

import "./ImageSlider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ImageSlider({ images, alt }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => {
          return (
            <div>
              <img src={image} key={index} alt={alt} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default ImageSlider;
