import ratheg from "../../../assets/images/projects/ratheg.jpeg";
import ratheg1 from "../../../assets/images/projects/ratheg1.png";
import ratheg2 from "../../../assets/images/projects/ratheg2.png";
import ratheg3 from "../../../assets/images/projects/ratheg3.png";
import yuman from "../../../assets/images/projects/yuman.png";
import yuman1 from "../../../assets/images/projects/yuman1.png";
import yuman2 from "../../../assets/images/projects/yuman2.png";
import yuman3 from "../../../assets/images/projects/yuman3.png";
import happets from "../../../assets/images/projects/happets.png";
import happets1 from "../../../assets/images/projects/happets1.png";
import happets2 from "../../../assets/images/projects/happets2.png";
import happets3 from "../../../assets/images/projects/happets3.png";
import james from "../../../assets/images/projects/james.png";
import james1 from "../../../assets/images/projects/james1.png";
import james2 from "../../../assets/images/projects/james2.png";
import james3 from "../../../assets/images/projects/james3.png";

export const projectDetails = [
  {
    title: "Rath Education Group",
    short: "ratheg",
    image: ratheg,
    path: "https://www.ratheg.com/",
    brief: "Website with blog",
    technologies: "Ruby on Rails, React, Postgres",
    actions: [
      "We created a presentational website for the customer",
      "The web design has been made in close contact with the client",
      "The website has a blog section, which has been custom-built",
      "The application is hosted by MIYU",
    ],
    images: [ratheg, ratheg1, ratheg2, ratheg3],
  },
  {
    title: "Yuman.io",
    short: "yuman",
    image: yuman,
    path: "https://www.yuman.io/",
    brief: "SaaS - CMMS system",
    technologies: "Ruby on Rails, React, Postgres, Stimulus, Bootstrap",
    actions: [
      "We have co-created this web application in a team of developers",
      "In the back-office, owners of maintenance companies can delegate and follow up the work of their technicians in the field",
      "A quoting and invoicing system makes it easy to handle all monthly accounting tasks",
      "A map functionality shows the location of the technicians, and the work to be performed in real-time",
      "Evolution in the supply stocks is followed-up upon in a specific dashboard",
    ],
    images: [yuman, yuman1, yuman2, yuman3],
  },
  {
    title: "Happets.com",
    short: "happets",
    image: happets,
    path: "https://www.happets.com/",
    brief: "Online marketplace for customized dog food",
    technologies: "React, Ruby on Rails, Postgres, Docker",
    actions: [
      "We formed part of a bigger team of developers to create and extend this platform",
      "Through a step-by-step wizard, the customer can describe their dog's physical appearance and food preferences",
      "Payment is done through Stripe, with integration of Google and Apple pay",
      "The customers dispose of an extensive Account space, where further customizations can be done (quantity, frequency, diet preferences...)",
      "A back-office enables the veterinarians to track all phases of an order and follow up with the customer",
      "All orders are passed on directly to the factory, where they are prepared and sent",
    ],
    images: [happets, happets1, happets2, happets3],
  },
  {
    title: "James",
    short: "james",
    image: james,
    path: "https://qa.james-cuddy.projects.wearemiyu.com/",
    brief: "Website with custom CMS",
    technologies: "React, Node.js, Mongoose, Express, Docker",
    actions: [
      "The client is a designer and designed his own website. We took care of the implementation",
      "In order to update the content, we created a custom content management system in which the client can create different components.",
      "All sections of the website are customizable through the CMS",
      "There's also a blog, which we have custom made for the client's needs",
      "We used node.js for all API calls",
    ],
    images: [james, james1, james2, james3],
  },
];
