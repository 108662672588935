import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Spinner.scss'

const Spinner = _props => {

  return <FontAwesomeIcon icon={faSpinner} />
}

Spinner.displayName = 'Spinner'

export default Spinner

