import React, { useState, useEffect } from "react";
import { images } from "../../assets/images/logoImages";
import "./Home.scss";

function Home() {
  const brandName = ["M", "I", "Y", "U"];
  const [moving, setMoving] = useState(false);

  const letters = brandName.map((val, index) => {
    return (
      <div className={`letter-box letter-${val}`} key={val}>
        <img
          src={images[`dotLine${val}`]}
          alt="dot-line"
          className={`dot-line ${val}`}
        />
        <img
          src={images[`dotBlack${val}`]}
          alt="dot-black"
          className={`dot-black ${val}`}
        />
        {val === "I" && (
          <img
            src={images[`dotBlackI`]}
            alt="dot-black"
            className={`dot-black stay`}
          />
        )}
        <div className={`slider ${moving ? "" : "close"}`}>
          <img src={images[`letter${val}`]} alt={`letter-${val}`} />
        </div>
      </div>
    );
  });

  useEffect(() => {
    setMoving(true);
  }, []);

  return (
    <div id="home">
      <div className="home-animation">
        <div className="flex logo-appearing">{letters}</div>
        <div className="subtitle">WEB DESIGN | APP DEVELOPMENT</div>
      </div>
    </div>
  );
}

export default Home;
