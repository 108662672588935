import React from "react";
import Irene from "../../assets/images/irene.png";
import Meg from "../../assets/images/meg.jpg";
import "./About.scss";

function About() {
  return (
    <div id="about">
      <div className="title">about us</div>
      <div className="about-wrapper">
        <div className="our-faces">
          <div className="face-image-meg">
            <img className="meg" src={Meg} alt="Hi, I'm Meg" />
          </div>
          <div className="presentation meg-presentation">
            <div className="name">MEG</div>
            <div className="role">web developer</div>
          </div>
          <div className="presentation irene-presentation">
            <div className="name">IRENE</div>
            <div className="role">web designer</div>
          </div>
          <div className="face-image-irene">
            <img className="irene" src={Irene} alt="Hi, I'm Irène" />
          </div>
        </div>
        <div className="intro-text">
          Miyu is the fruit of a collaboration between graphic design studio{" "}
          <a href="https://www.maisonirene.eu/" target="_blank" rel="noopener">
            Maison Irène
          </a>{" "}
          and web developers collective Megenfelixendekat. Together we represent
          the perfect symbiosis of technical knowhow and beautiful designs.
          <br />
          <br />
          For both small and large companies, we aim for a personal approach.
          Good communication is the key to client satisfaction.
          <br />
          <br />
          We take care of the full process, from design to development and
          hosting. Whatever your needs are, we have the solution.
        </div>
      </div>
    </div>
  );
}

export default About;
