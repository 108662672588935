import React from "react";
import { useApp } from "./context/AppContext";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Work from "./pages/work/Work";
import Contact from "./pages/contact/Contact";
import Header from "./components/Header/Header";
import Dot from "./components/Dot/Dot";
import { motion, AnimatePresence } from "framer-motion";
import "./app.scss";

function App() {
  const { menuList, currentStep, setCurrentStep } = useApp();
  const dots = menuList.map((val, index) => {
    return (
      <Dot
        key={index}
        solid={currentStep === val}
        onClick={() => setCurrentStep(val)}
      />
    );
  });
  let vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  return (
    <div className="app">
      <Header
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        menuList={menuList}
      />
      <AnimatePresence initial={false}>
        {currentStep === "home" && (
          <motion.div
            initial={{ x: vw, y: 0 }}
            animate={{ x: 0, y: 0 }}
            exit={{ x: -vw, y: 0 }}
            key="home"
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            <Home />
          </motion.div>
        )}
        {currentStep === "work" && (
          <motion.div
            initial={{ x: vw }}
            animate={{ x: 0 }}
            exit={{ x: -vw }}
            key="work"
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            <Work />
          </motion.div>
        )}
        {currentStep === "about" && (
          <motion.div
            initial={{ x: vw }}
            animate={{ x: 0 }}
            exit={{ x: -vw }}
            key="about"
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            <About />
          </motion.div>
        )}
        {currentStep === "contact" && (
          <motion.div
            initial={{ x: vw }}
            animate={{ x: 0 }}
            exit={{ x: -vw }}
            key="contact"
            transition={{
              ease: "easeInOut",
              duration: 0.5,
            }}
          >
            <Contact />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="footer">{dots}</div>
    </div>
  );
}

export default App;
