import letterM from "./letter-m.png";
import letterI from "./letter-i.png";
import letterY from "./letter-y.png";
import letterU from "./letter-u.png";
import dotBlackM from "./dot-black-m.png";
import dotLineM from "./dot-line-m.png";
import dotBlackI from "./dot-black-i.png";
import dotLineI from "./dot-line-i.png";
import dotBlackY from "./dot-black-y.png";
import dotLineY from "./dot-line-y.png";
import dotBlackU from "./dot-black-u.png";
import dotLineU from "./dot-line-u.png";

export const images = {
  letterM,
  letterI,
  letterY,
  letterU,
  dotBlackM,
  dotLineM,
  dotBlackI,
  dotLineI,
  dotBlackY,
  dotLineY,
  dotBlackU,
  dotLineU,
}
