import React from "react";
import Spinner from "../Spinner/Spinner";

import "./Button.scss";

function Button({ text, isLoading, disabled, className }) {
  return (
    <button
      type="submit"
      className={`submit-button ${className}`}
      disabled={disabled}
    >
      {isLoading ? <Spinner /> : <strong>{text}</strong>}
    </button>
  );
}

export default Button;
